<template>
  <div id="round-won-tickets" class="container-fluid">
    <div class="page-header mb-1">
      <strong>โพยที่ถูกรางวัล</strong>
    </div>
    <b-card no-body class="mb-1 position-relative">
      <b-card-header header-tag="nav">
        <h5 class="card-title mb-0">
          <i class="fas fa-exclamation-triangle text-danger mr-2"></i>
          <span class="text-dark mr-2">เฉพาะ</span>
          <span class="text-primary mr-2">{{ roundName }}</span>
          <span class="text-dark mr-2">วันที่</span>
          <span class="text-primary mr-2">{{ roundDate }}</span>
          <small class="text-secondary">(เปลี่ยนได้ที่แถบเมนูด้านบน)</small>
        </h5>
      </b-card-header>

      <b-card-body class="p-2">
        <ol v-if="items.length" class="breadcrumb pl-2 pr-0 py-0 m-0 mb-2 border-info d-flex justify-content-between align-items-center">
          <li class="breadcrumb-item">
            <span>แสดง {{ startRow | amountFormat(0) }} - {{ endRow | amountFormat(0) }} จาก {{ summary.totalTicket | amountFormat(0) }} รายการ</span>
          </li>
          <li>
            <ul class="pagination justify-content-end mb-0">
              <li
                v-for="(page, index) in pages"
                :key="page.number"
                class="page-item"
                :class="[{'active': page.number === currentPage}]"
                style="cursor: pointer; border-radius: 0;"
              ><a class="page-link rounded-0" @click="togglePage(page)">{{page.number}}</a></li>
            </ul>
          </li>
        </ol>

        <table class="table table-bordered table-secondary mb-0">
          <thead>
            <tr>
              <th width="40">ลำดับ</th>
              <th width="100">เลขที่</th>
              <th width="180" class="pb-1">ชื่อใช้งาน
                <b-form-checkbox
                  v-model="showName"
                  :value="true"
                  :unchecked-value="false"
                  class="mt-2 font-weight-normal"
                >
                  แสดงชื่อ
                </b-form-checkbox>
              </th>
              <th width="140">อัตราจ่าย</th>
              <th width="180">เวลา</th>
              <th width="100">รายการ</th>
              <th width="100">ยอดบิล</th>
              <th width="100">ส่วนลด</th>
              <th width="100">ถูกรางวัล</th>
              <th width="100">ได้เสีย</th>
              <th width="120">สถานะ</th>
              <th>หมายเหตุ</th>
              <th width="60"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="items.length" class="alert-warning">
              <th class="text-center" colspan="5">สรุปยอดรวมทั้งหมด</th>
              <th class="text-center">{{summary.length | amountFormat(0)}}</th>
              <th class="text-right text-success">{{summary.amount | amountFormat}}</th>
              <th class="text-right text-danger">{{summary.discount | amountFormat}}</th>
              <th
                class="text-right"
                :class="[{
                  'text-success': summary.won>0
                }, {
                  'text-danger': summary.won<0
                }]"
              >{{summary.won | amountFormat}}</th>
              <th
                class="text-right"
                :class="[{
                  'text-success': summary.total>0
                }, {
                  'text-danger': summary.total<0
                }]"
              >{{summary.total | amountFormat}}</th>
              <th colspan="3"></th>
            </tr>
            <tr v-for="(item, index) in items" :key="item._id">
              <td class="text-center">{{offset+index+1}}</td>
              <td class="text-center">{{item.ticketNo}}</td>
              <td class="text-center">
                {{ item.account.mainUser.username }}
                <small v-if="showName" class="text-secondary">({{item.account.mainUser.firstName}})</small>
              </td>
              <td class="text-center">{{item.rate.rateTitle}}</td>
              <td class="text-center">
                {{item.createdAt | dispDateTime("DD/MM/YYYY HH:mm:ss")}}
              </td>
              <td class="text-center">{{item.summary.length}}</td>
              <td class="text-right text-success">{{item.summary.amount | amountFormat}}</td>
              <td class="text-right text-danger">{{item.summary.discount | amountFormat}}</td>
              <td class="text-right text-success">{{item.summary.won | amountFormat}}</td>
              <td
                class="text-right"
                :class="[{
                  'text-success': item.summary.total>0
                }, {
                  'text-danger': item.summary.total<0
                }]"
              >{{item.summary.total | amountFormat}}</td>
              <td class="text-center">
                <TicketStatus :status="item.status" />
              </td>
              <td class="text-center">{{item.remarks}}</td>
              <td class="text-center">
                <button class="btn btn-outline-info btn-sm" @click="viewTicket(item._id)"><i class="fas fa-list"></i></button>
              </td>
            </tr>
          </tbody>
          <tbody v-if="isLoading">
            <tr>
              <td colspan="14" class="text-center">กรุณารอซักครู่</td>
            </tr>
          </tbody>
          <tbody v-if="!isLoading && !this.items.length">
            <tr>
              <td colspan="14" class="text-center">ไม่มีโพยถูกรางวัล</td>
            </tr>
          </tbody>
        </table>

        <ViewTicketModal :is-show="isShowTicketModal" :ticket-id="viewTicketId" @close="isShowTicketModal=false" />
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import ReportService from "@/services/ReportService"
import Swal from 'sweetalert2'
import _ from 'lodash'
import moment from '@/helpers/moment'
import ViewTicketModal from '@/views/setting-report/components/ViewTicketModal'
import cAlert from '@/helpers/alert'

export default {
  name: 'RoundWonTickets',
  components: {
    ViewTicketModal
  },
  data() {
    return {
      data: null,
      isLoading: false,
      showName: false,
      isShowTicketModal: false,
      viewTicketId: null,
      currentPage: 1
    }
  },
  computed: {
    roundId() {
      return this.$store.state.globalMarket?.round?.roundId
    },
    roundName() {
      if(!this.data)
        return ''

      // return `[${this.data.round.note.groupTitle}] ${this.data.round.note.marketTitle}`
      return `หวย${this.data.round.note.marketTitle}`
    },
    roundDate() {
      if(!this.data)
        return ''

      return moment(this.data.round.roundDate.date).format("DD/MM/YYYY")
    },
    items() {
      if(!this.data)
        return []

      return this.data.tickets.map((item)=>{
        item.summary.discount = -item.summary.discount
        item.summary.total = (-item.summary.amount - item.summary.discount + item.summary.won)
        return item
      })
    },
    summary() {
      const totalAmount = this.data?.overall?.totalAmount || 0
      const totalDiscount = this.data?.overall?.totalDiscount || 0
      const totalNumber = this.data?.overall?.totalNumber || 0
      const totalWon = this.data?.overall?.totalWon || 0
      const total = totalAmount - totalDiscount - totalWon

      return {
        amount: totalAmount,
        discount: -totalDiscount,
        length: totalNumber,
        won: -totalWon,
        total: total,
        totalTicket: this.data?.overall?.totalWonTicket || 0
      }
    },
    pages() {
      const pages = []
      const perPage = 1000
      const totalPage = Math.ceil(this.summary.totalTicket/perPage)
      for(let i=1; i<=totalPage; i++) {
        pages.push({
          number: i,
          offset: (i * perPage) - perPage
        })
      }
      return pages
    },
    offset() {
      const page = this.pages.find(p=>p.number===this.currentPage)
      const offset = page?.offset || 0
      return offset
    },
    startRow() {
      return this.offset + 1
    },
    endRow() {
      const max = this.startRow + 1000 - 1
      return max < this.summary.totalTicket ? max : this.summary.totalTicket
    }
  },
  watch: {
    roundId() {
      if(this.roundId)
        this.getReports()
    }
  },
  methods: {
    getReports() {
      this.isLoading = true
      ReportService.getRoundWonTickets(this.roundId, this.offset)
      .then((response)=>{
        if(response.success) {
          this.data = response.data
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message || 'โหลดข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    viewTicket(ticketId) {
      this.isShowTicketModal = true
      this.viewTicketId = ticketId
    },
    togglePage(page) {
      this.currentPage = page.number
      this.getReports()
    }
  },
  mounted() {
    if(this.roundId)
      this.getReports()
  }
}
</script>
<style lang="scss" scoped>
.table {
  thead {
    tr {
      th {
        text-align: center;
        font-weight: 500;
        line-height: 1;
        font-size: 85%;
      }
    }
  }
  tbody {
    background-color: #FFF;
    tr {
      td {
        padding: 5px;
        vertical-align: middle;
        font-size: 85%;
      }
      th {
        padding: 5px;
        font-size: 85%;
      }
    }
  }
  tfoot {
    tr {
      td {
        font-size: 85%;
      }
    }
  }
}
</style>
<style lang="scss">
.table-win-tickets {
  tbody {
    tr {
      td {
        .badge {
          font-size: 90%;
          font-weight: normal;
        }
      }
    }
  }
}
</style>
